import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLanderConfig } from "../commons/ConfigContext";
import { EventType, QueryParam, System } from "../commons/Constants";
import { postEvent } from "../commons/EventHelper";
import { createBaseLanderEvent } from "../commons/LanderEvent";
import { Settings } from "../config";
import { LanderConfig } from "../types/LanderConfig";
import { VerizonContent } from "../verizon/VerizonContent";
import { AdSense } from "./utils/adSenseUtils";
import loadAdsense from "./utils/loadAdsense";
import { namefindSerpLink } from "../experimentation/namefindHelpers";

function AdSenseBlock({ landerConfig, page, queryConfig }) {
  let adsenseContentDivId = "ads";
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = "relatedLinks";
  }

  let search;
  if (
    !landerConfig.lander.template.includes("PARK_WEB") &&
    queryConfig[QueryParam.SEARCH_BOX]
  ) {
    search = (
      <div id="searchbox">
        <FormattedMessage id="contentBlockedText" />
      </div>
    );
  }

  return (
    <>
      <div id={ adsenseContentDivId }>
        <FormattedMessage id="contentBlockedText" />
      </div>
      {search}
    </>
  );
}

export function AdContent({ page }: { page: string }) {
  const [loadingAdsense, setLoadingAdsense] = useState(false); // Make sure we only load it once
  const [isAdsenseSuccess, setIsAdsenseSuccess] = useState(true);
  const { landerConfig, queryConfig, landerConfigDispatch } = useLanderConfig();

  useEffect(() => {
    if (!landerConfig.isForcedVerizon && !loadingAdsense) {
      setLoadingAdsense(true);
      loadAdsense(landerConfig, queryConfig, page, (success) => {
        setIsAdsenseSuccess(success);
      });
    }
  }, [
    landerConfig,
    page,
    queryConfig,
    setIsAdsenseSuccess,
    landerConfigDispatch,
    loadingAdsense,
    setLoadingAdsense,
  ]);

  if (landerConfig.isForcedVerizon) {
    return <VerizonContent />;
  }

  if (!isAdsenseSuccess) {
    // If adsense fails...
    if (landerConfig.system === System.PARKWEB) {
      redirectToSerp(landerConfig.lander.banner?.link, landerConfig);
    } else {
      // The CP api doesn't return a serp link, so we need to construct it
      redirectToSerp(
        namefindSerpLink(
          landerConfig.domain.rootDomain,
          "x_dom-other_parkedpages_x_x_invest_b_001" // From Product side
        ),
        landerConfig
      );
    }

    // placeholder when redirecting
    return <></>;
  }

  return (
    <AdSenseBlock
      landerConfig={ landerConfig }
      queryConfig={ queryConfig }
      page={ page }
    />
  );
}

function redirectToSerp(url: string, landerConfig: LanderConfig) {
  const serpLink = url + "&redirect=true";
  postEvent(Settings.EVENT_PUBLISH_API, {
    ...createBaseLanderEvent(EventType.SERP_REDIRECT, landerConfig),
    serpLink,
  });
  window.location.assign(serpLink);
}
